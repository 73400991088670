.searchBar {
  // margin-top: 70px;
  padding: 15px;
}

.searchBar__input {
  @include placeholder-color($color-navy);
  background-color: $color-sand;
  width: 100%;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  color: $color-navy;
  border: 1px solid $color-sand;
  border-radius: 0.25rem;
}
