// overriding bootstrap variables in _boostrap-override.scss
$family-base: 'FunkisADotsBold';
$family-base2: 'FunkisADotsRegular';
$family-header: 'Grenette';

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-light;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;
$color-navy: #143764;
$color-marineBlue: #5064b9;
$color-sand: #fff5eb;
$color-seaGreen: #5ac8c8;
$color-spritz: #ffa087;

// site colors
$primary-color: $color-navy;
$font-color: $color-navy;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $color-seaGreen;
$link-hover-color: $color-spritz;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 60px,
  tablet: 60px,
  desktop: 60px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
