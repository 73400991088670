.tilesBig {
  margin-left: 0;
  margin-right: 0;
}
.container .cms_item.tilesBig {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}
.tilesBig .slick-list {
  overflow: visible;
}
.tilesBig .slick-slider {
  width: 373px;
  @media screen and (min-width: 992px) {
    width: 908px;
  }
  @media screen and (min-width: 1200px) {
    width: 1041px;
  }
  @media screen and (min-width: 1360px) {
    width: 1233px;
  }
  @media screen and (min-width: 1600px) {
    width: 1400px;
  }
  @media screen and (min-width: 2500px) {
    width: 1527px;
  }
}
.tilesBig .slick-slide {
  opacity: 0.3;
}
.tilesBig .slick-slide.slick-active {
  opacity: 1;
}
.tileBig .pageTile__content.tile__content {
  position: relative;
}
.tilesBig .slick-slide .pageTile_details {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.tilesBig .slick-active:hover .pageTile_details,
.tilesBig .slick-active:active .pageTile_details {
  display: block;
}
.tilesBig .tileBig .tile__bg,
.pageTile__fourth__bg {
  width: 100%;
  height: 250px;
  @media screen and (min-width: 700px) {
    height: 185px;
  }
  @media screen and (min-width: 992px) {
    height: 200px;
  }
  @media screen and (min-width: 1200px) {
    height: 260px;
  }
  @media screen and (min-width: 1360px) {
    height: 276px;
  }
  @media screen and (min-width: 1600px) {
    height: 300px;
  }
  @media screen and (min-width: 2500px) {
    height: 350px;
  }
}
.tileBig .pageTile_details {
  width: 100%;
  height: 250px;
  padding: 20px;
  @media screen and (min-width: 700px) {
    height: 185px;
    padding: 10px;
  }
  @media screen and (min-width: 992px) {
    height: 200px;
    padding: 14px;
  }
  @media screen and (min-width: 1200px) {
    height: 260px;
    padding: 20px;
  }
  @media screen and (min-width: 1360px) {
    height: 276px;
  }
  @media screen and (min-width: 1600px) {
    height: 300px;
  }
  @media screen and (min-width: 2500px) {
    height: 350px;
    padding: 30px;
  }
}
.tilesBig .pageTile_details,
.tileBig .pageTile__content:hover .pageTile_details,
.tileBig .pageTile__content:focus .pageTile_details {
  background-color: white;
  cursor: grab;
}
.tileBig .pageTile__content:hover .pageTile__title,
.tileBig .pageTile__content:hover .pageTile_desc,
.tileBig .pageTile__content:active .pageTile__title,
.tileBig .pageTile__content:active .pageTile_desc,
.tileBig .pageTile__content:focus .pageTile__title,
.tileBig .pageTile__content:focus .pageTile_desc {
  color: $color-navy;
}
.tileBig .tile_button {
  color: $color-sand;
  border-radius: 4px;
  border: 0;
  background: $color-marineBlue;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover,
  &:active {
    background: $color-spritz;
    text-decoration: none;
  }
  @media screen and (min-width: 992px) {
    font-size: 0.6rem;
  }
  @media screen and (min-width: 992px) {
    font-size: 0.7rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
}
.tileBig .pageTile__title {
  font-size: 1rem;
  margin: 0.8rem 0;
  font-family: $family-base;
  @media screen and (min-width: 700px) {
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }
  @media screen and (min-width: 992px) {
    font-size: 0.8rem;
    margin: 0 0 0.4rem 0;
  }
  @media screen and (min-width: 1200px) {
    font-size: 0.9rem;
    margin: 0.5rem 0 1rem 0;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 2500px) {
    font-size: 1.2rem;
    margin: 1.5rem 0;
  }
}
.tileBig .pageTile_desc {
  line-height: 1.1;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: $family-base2;
  @media screen and (min-width: 700px) {
    line-height: 1.2;
    font-size: 0.55rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 992px) {
    line-height: 1;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
  }
  @media screen and (min-width: 1200px) {
    line-height: 1.1;
    font-size: 0.7rem;
    margin-bottom: 1.1rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 2500px) {
    line-height: 1.2;
    margin-bottom: 1.6rem;
  }
}
.tileBig .pageTile__title_bg {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  padding: 2rem 0.8rem 0.4rem;
  font-size: 1.3rem;
  line-height: 1.2;
  color: white;
  height: 25%;
  background: linear-gradient(180deg, transparent 0, #000);
  font-family: $family-base;
  display: flex;
  align-items: center;
  @media screen and (min-width: 700px) {
    padding: 1rem 0.8rem 0.4rem;
    font-size: 0.9rem;
    height: 39%;
  }
  @media screen and (min-width: 992px) {
    padding: 1rem 0.8rem 0.4rem;
    font-size: 0.5rem;
    height: 23%;
  }
  @media screen and (min-width: 1200px) {
    padding: 1rem 0.6rem 0.4rem;
    font-size: 0.6rem;
    height: 19%;
  }
  @media screen and (min-width: 1600px) {
    height: 23%;
    font-size: 1rem;
  }
  @media screen and (min-width: 2500px) {
    padding: 2rem 0.8rem 0.4rem;
    height: 25%;
  }
}
.tilesBig .slick-prev:before,
.tilesBig .slick-next:before {
  font-size: 50px;
  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
  @media screen and (min-width: 2500px) {
    font-size: 50px;
  }
}
.container .cms_item.tilesBig .slick-prev {
  display: none !important;
  @media screen and (min-width: 1200px) {
    display: block !important;
    left: -44px;
  }
  @media screen and (min-width: 2500px) {
    left: -70px;
  }
}
.container .cms_item.tilesBig .slick-next {
  display: none !important;
  @media screen and (min-width: 1200px) {
    display: block !important;
    right: -36px;
  }
  @media screen and (min-width: 2500px) {
    right: -36px;
  }
}
.tilesBig .slick-dots li button:before {
  font-size: 10px;
}
