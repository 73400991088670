@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import '~aos/src/sass/aos';

@import './youtubeNew.scss';
@import './photoSlide.scss';

@media print {
  @import './print';
}

// banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}
.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
  }
  div.file__attach__text {
    margin-left: 1rem;
  }
}
body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  div.cms_item.mobile-ig-feed {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

.topHeader_style_scrolled .line1,
.topHeader_style_scrolled .line2,
.topHeader_style_scrolled .line3 {
  background-color: #143764;
}

.contentPanel .button.secondary {
  color: $color-sand;
  border-radius: 4px;
  background: $color-marineBlue;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover {
    background: $color-spritz;
    text-decoration: none;
  }
}
.contentPanel .button.third {
  color: $color-sand;
  border-radius: 4px;
  background: $color-marineBlue;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover {
    background: $color-seaGreen;
    text-decoration: none;
  }
}

.main-content {
  overflow: hidden;
}

.topHeader_style .masthead_2,
.topHeader_style_scrolled .masthead_2 {
  display: none;
}
.topHeader_style .masthead_3,
.topHeader_style_scrolled .masthead_3 {
  display: none;
}
.topHeader_style .masthead_4,
.topHeader_style_scrolled .masthead_4 {
  display: none;
}
.topHeader_style .masthead_5,
.topHeader_style_scrolled .masthead_5 {
  display: none;
}
.topHeader_style .masthead_6,
.topHeader_style_scrolled .masthead_6 {
  display: none;
}
// Masthead-Theme-1
.Masthead-Theme-1 .masthead_1 {
  display: block;
}
.Masthead-Theme-1 .masthead_2 {
  display: none;
}
.Masthead-Theme-1 .masthead_3 {
  display: none;
}
.Masthead-Theme-1 .masthead_4 {
  display: none;
}
.Masthead-Theme-1 .masthead_5 {
  display: none;
}
.Masthead-Theme-1 .masthead_6 {
  display: none;
}
// Masthead-Theme-2
.Masthead-Theme-2 .masthead_1 {
  display: none;
}
.Masthead-Theme-2 .masthead_2 {
  display: block;
}
.Masthead-Theme-2 .masthead_3 {
  display: none;
}
.Masthead-Theme-2 .masthead_4 {
  display: none;
}
.Masthead-Theme-2 .masthead_5 {
  display: none;
}
.Masthead-Theme-2 .masthead_6 {
  display: none;
}
.Masthead-Theme-2 .topHeader_style .masthead_2 {
  height: 130px;
  margin-top: 6rem;
}
// Masthead-Theme-3
.Masthead-Theme-3 .masthead_1 {
  display: none;
}
.Masthead-Theme-3 .masthead_2 {
  display: none;
}
.Masthead-Theme-3 .masthead_3 {
  display: block;
}
.Masthead-Theme-3 .masthead_4 {
  display: none;
}
.Masthead-Theme-3 .masthead_5 {
  display: none;
}
.Masthead-Theme-3 .masthead_6 {
  display: none;
}
.Masthead-Theme-3 img.masthead_3 {
  @media screen and (max-width: 576px) {
    height: 35px;
  }
}
.Masthead-Theme-3 .topHeader_style .masthead_3 {
  height: 120px;
  margin-top: 5rem;
}
// Masthead-Theme-4
.Masthead-Theme-4 .masthead_1 {
  display: none;
}
.Masthead-Theme-4 .masthead_2 {
  display: none;
}
.Masthead-Theme-4 .masthead_3 {
  display: none;
}
.Masthead-Theme-4 .masthead_4 {
  display: block;
}
.Masthead-Theme-4 .masthead_5 {
  display: none;
}
.Masthead-Theme-4 .masthead_6 {
  display: none;
}
.Masthead-Theme-4 .topHeader_style .masthead_4 {
  height: 125px;
  margin-top: 5rem;
}
// Masthead-Theme-5
.Masthead-Theme-5 .masthead_1 {
  display: none;
}
.Masthead-Theme-5 .masthead_2 {
  display: none;
}
.Masthead-Theme-5 .masthead_3 {
  display: none;
}
.Masthead-Theme-5 .masthead_4 {
  display: none;
}
.Masthead-Theme-5 .masthead_5 {
  display: block;
}
.Masthead-Theme-5 .masthead_6 {
  display: none;
}
.Masthead-Theme-5 .topHeader_style .masthead_5 {
  height: 130px;
  margin-top: 6rem;
}
// Masthead-Theme-6
.Masthead-Theme-6 .masthead_1 {
  display: none;
}
.Masthead-Theme-6 .masthead_2 {
  display: none;
}
.Masthead-Theme-6 .masthead_3 {
  display: none;
}
.Masthead-Theme-6 .masthead_4 {
  display: none;
}
.Masthead-Theme-6 .masthead_5 {
  display: none;
}
.Masthead-Theme-6 .masthead_6 {
  display: block;
}
.Masthead-Theme-6 .topHeader_style .masthead_6 {
  height: 130px;
  margin-top: 6rem;
}

.topHeader_style .bg_dark_linear {
  display: block;
  width: 100%;
  height: 224%;
  background: linear-gradient(180deg, #000 0, transparent);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
div#rcm-web-booking-module {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cms_item.big-panel {
  max-width: 1140px;
  padding: 10px;
}
.cms_item.contentPanel.big-panel .container {
  margin: 0;
  padding: 0;
}

// html {
//   scroll-behavior: smooth;
//   transition: $transition-fast;
// }
