@font-face {
  font-family: Grenette;
  src: url('./grenette-semibold-trial.otf') format('opentype');
}
@font-face {
  font-family: 'FunkisADotsBold';
  src: url('./FunkisADotsBold.otf') format('opentype');
}
@font-face {
  font-family: 'FunkisADotsRegular';
  src: url('./FunkisADotsRegular.otf') format('opentype');
}
