.main-content {
  // padding-top: map-get($top-bar-height, mobile);

  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  button {
    margin-right: 0.7rem;
    background: $color-seaGreen;
    border-color: $link-color;
    color: $color-sand;
    border-radius: 4px;
    font-weight: $weight-bold;
    &:hover {
      background: $color-spritz;
      border-color: $color-spritz;
    }
  }
}
